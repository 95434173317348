<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.keyword" placeholder="用户昵称/手机号/ID"></el-input>
          </el-col>
          <el-col :span="12">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
            </div>
          </el-col>
          <el-col :span="1">
            <user-create @change="userCreate"></user-create>
          </el-col>
        </el-row>

      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column label="用户信息">
          <template #default="s">
            <div v-if="s.row.user.id > 0" class="flex-def">
              <img v-if="s.row.user.avatar" style="margin-right: .5rem;height: 3rem;width: 3rem;border-radius: 5px" :src="s.row.user.avatar | tomedia" alt="">
              <div>
                <div>{{s.row.user.nickname}}({{s.row.user.id}})</div>
                <div>{{s.row.user.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员状态">
          <template #default="s">
            <span v-if="s.row.is_member" style="color: #6fdf7c">免单会员</span>
          </template>
        </el-table-column>
        <el-table-column prop="wait" label="未使用"></el-table-column>
        <el-table-column prop="used" label="已使用"></el-table-column>
        <el-table-column label="操作" width="300">
          <template v-slot="s">
            <div class="flex-def">
              <user-code-list :uid="s.row.uid" :nickname="s.row.user.nickname">
                <el-button style="background-color: #982ce6;color: #FFFFFF">激活码明细</el-button>
              </user-code-list>
              <create-code @created="load" :uid="s.row.uid">
                <el-button style="background-color: #19a6eb;color: #FFFFFF">生成激活码</el-button>
              </create-code>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import UserCreate from "@/view/card/plugin/cdkey/userCreate.vue";
import CreateCode from "@/view/card/plugin/cdkey/createCode.vue";
import UserCodeList from "@/view/card/plugin/cdkey/userCodeList.vue";

export default {
  components: {
    UserCodeList,
    CreateCode,
    UserCreate

  },

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        keyword:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    userCreate(e){
      this.$u.api.card.plugin.cdkey.userAdd({uid:e.id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    searchGo(){
      this.page = 1;
      this.list = [];
      this.load();
    },
    searchReset(){
      this.searchForm = {
        keyword:"",
      }
      this.searchGo()
    },
    load() {
      this.$u.api.card.plugin.cdkey.userSearch({
        page:this.page,
        ...this.searchForm
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>